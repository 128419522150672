import React, { useEffect, useRef, useState } from "react";
import "./PDF.css";
import Head from "../../../components/PDF/Head/Head";
import About from "../../../components/PDF/About/About";
import Core from "../../../components/PDF/Core/Core";
import Past from "../../../components/PDF/Past Performance/Past";
import CCP from "../../../components/PDF/CCP/CCP";
import { FaEdit } from "react-icons/fa";
import { Button, FormControl, InputGroup, Modal } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ColorPicker from "../../../components/PDF/Color/ColorPicker";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  EditCapabilityStatement,
  SaveCapabilityStatement,
} from "../../../store/actions/PDF/pdf.actions";
import { useLocation, useNavigate } from "react-router-dom";
import "paper-css/paper.css";
import Tooltip from "react-bootstrap/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { toastSuccess } from "../../../utils/helpers/helper"; // Import the Paper CSS stylesheet
import { STATEMENT_A_SET_VALUES } from "../../../store/actionTypes";
import { getUserDetail } from "../../../store/actions/users/users.actions";
import { State } from "country-state-city";
import { useFeatureAccess } from "../../../hooks/useFeatureAccess";
import { getBasicCompanyDetails } from "../../../store/actions/company_details/companyDetails.actions";

const PDFVersion_A = ({ onOverview, onEdit }) => {
  const Data = useSelector((state) => state.pdf);
  const values = useSelector((state) => state.statementValues);
  const pdf = Data.values;
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [name, setName] = useState(pdf?.pdf_name);
  const { user } = useSelector((state) => state.auth);
  const { user: profile } = useSelector((state) => state.userDetails);
  const { id } = user ? user : -1;
  const linkInputRef = useRef(null);

  const pdfWrapper = useRef(null);

  const [borderColor, setBorderColor] = useState("black");
  const [isEditMode, setIsEditMode] = useState(false);
  const [showPopup, setShowPopup] = useState(false); // State to control the pop-up
  const [logoUrl, setLogoUrl] = useState("");
  const [pdfName, setPdfName] = useState("");
  const [showShare, setShowShare] = useState(false);
  const companyBasicInfo = useSelector((state) => state.basicInfo);
  const { loading, error, basic_info } = companyBasicInfo;
  const { hasFeatureAccess, enforceAccess } = useFeatureAccess(
    "Capability Statement"
  );

  useEffect(() => {
    onEdit(isEditMode);
  }, [isEditMode]);

  useEffect(() => {
    if (user) {
      dispatch(getUserDetail());
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (pdf?.pdf_name || pdf?.id) {
      dispatch({
        type: STATEMENT_A_SET_VALUES,
        payload: {
          ...pdf,
          core_competencies_image:
            pdf?.core_competencies_image ||
            "https://res.cloudinary.com/dc367rgig/image/upload/v1694361216/b._ganarpro_2_ocl5e6.jpg",
          userId: id,
          version: "A",
        },
      });
    } else {
      dispatch(getBasicCompanyDetails());
      let state = State.getStatesOfCountry("US");
      const filteredState = state.filter((s) => s.name === user?.company_state);
      const isoCode = filteredState.length > 0 ? filteredState[0].isoCode : "";

      dispatch({
        type: STATEMENT_A_SET_VALUES,
        payload: {
          ...pdf,
          company_info: basic_info?.company_name,
          company_address1: user?.company_street || basic_info?.office_address,
          company_address2: `${
            user?.company_city || basic_info?.office_city
          }, ${basic_info?.office_state} ${basic_info?.office_zip}`,
          owner_name: `${user.first_name} ${user?.last_name}`,
          owner_email: user?.email,
          owner_phone: user?.phone,
          url: profile?.domain_host_url || basic_info?.company_website,
          logo_image: profile?.file_url || pdf.logo_image,
          userId: id,
          version: "A",
          about_us: basic_info?.about_us,
        },
      });
    }
    setName(pdf?.pdf_name);
  }, [
    basic_info?.about_us,
    basic_info?.company_name,
    basic_info?.company_website,
    basic_info?.office_address,
    basic_info?.office_city,
    basic_info?.office_state,
    basic_info?.office_zip,
    dispatch,
    id,
    pdf,
    pdf?.id,
    profile?.domain_host_url,
    profile?.file_url,
    user?.company_city,
    user?.company_state,
    user?.company_street,
    user?.email,
    user.first_name,
    user?.last_name,
    user?.phone,
  ]);

  const handleOnChange = (e) => {
    dispatch({
      type: STATEMENT_A_SET_VALUES,
      payload: {
        [e.target.name]: e.target.value,
      },
    });
  };
  const handleEditClick = () => {
    setIsEditMode(true);
  };
  const handleBorderColorChange = (color) => {
    setBorderColor(color);
  };

  const handlePopup = (e) => {
    e.preventDefault();
    setIsEditMode(false);
    setShowPopup(true); // Show the pop-up
  };
  const handleSave = (e) => {
    let formData = new FormData();
    for (const key in values) {
      if (values[key] !== undefined) {
        formData.append(key, values[key]);
      }
    }
    if (!hasFeatureAccess) {
      enforceAccess();
    } else {
      if (name === values.pdf_name) {
        dispatch(
          EditCapabilityStatement(formData, setIsEditMode, setShowPopup)
        );
      } else {
        dispatch(
          SaveCapabilityStatement(formData, setIsEditMode, setShowPopup)
        );
        setName(values.pdf_name);
      }
    }
  };

  const handlePrint = (e) => {
    setIsEditMode(false);
    e.preventDefault();
    document.title = values.pdf_name;
    window.print();
    document.title = "Ganarpro";
  };

  const handleClose = () => {
    setShowPopup(false); // Close the pop-up
  };
  // const handleCopyToClipboard = () => {
  //   if (linkInputRef.current) {
  //     linkInputRef.current.select();
  //     document.execCommand("copy");
  //   }
  // };
  function getRootDomain() {
    return `${window.location.protocol}//${window.location.hostname}`;
  }
  const handleCopyToClipboard = async () => {
    if (values.unique_link) {
      try {
        const rootDomain = getRootDomain();
        await navigator.clipboard.writeText(
          `${rootDomain}/capability_statement/share/${values.unique_link}`
        );
        toastSuccess("Link copied to clipboard");
        // Optional: You can add a notification here to inform the user that the link has been copied.
      } catch (err) {
        console.error("Failed to copy the link: ", err);
      }
    }
  };

  return (
    <>
      <div id="nb" className="actions_bar">
        <div className="edit">
          <ColorPicker
            borderColor={borderColor}
            onBorderColorChange={handleBorderColorChange}
          />
          {!isEditMode && (
            <>
              <div className="edit-button" onClick={handleEditClick}>
                <span>Edit</span>
                <FaEdit size={20} />
              </div>
            </>
          )}
          {isEditMode && (
            <Button
              variant="danger"
              size="lg"
              onClick={() => setIsEditMode(false)}
            >
              Cancel
            </Button>
          )}
          {user && values.pdf_name && (
            <Button
              variant="primary"
              onClick={() => setShowShare(true)}
              className="rounded-4 "
            >
              <FontAwesomeIcon icon={faCopy} size="xl" className="px-2" />
              <span className="fa-lg">Share</span>
            </Button>
          )}
        </div>
        <div>
          <Button
            variant="primary"
            size="lg"
            onClick={onOverview}
            style={{ fontSize: "17px" }}
          >
            Overview
          </Button>
        </div>
      </div>
      <div id="pdfContainer" className="A4">
        <div id="pdf" className="A4 PDF_main" style={{ borderColor }}>
          <Head
            handleOnChange={handleOnChange}
            intialState={values}
            isEditMode={isEditMode}
            Logo_Url={Data?.logo_url}
            page="VersionA"
          />
          <br />
          <About
            handleOnChange={handleOnChange}
            intialState={values}
            isEditMode={isEditMode}
          />
          <br />
          <Core
            handleOnChange={handleOnChange}
            intialState={values}
            isEditMode={isEditMode}
            page="VersionA"
          />
          <br />
          <Past
            handleOnChange={handleOnChange}
            intialState={values}
            isEditMode={isEditMode}
            page="VersionA"
          />
          <br />
          <CCP
            handleOnChange={handleOnChange}
            intialState={values}
            isEditMode={isEditMode}
            page="VersionA"
          />
        </div>
      </div>

      <div className="s-p" id="nb">
        <Button variant="primary" size="lg" onClick={handlePopup}>
          Save
        </Button>
        <Button
          variant="primary"
          size="lg"
          onClick={handlePrint}
          className={isEditMode ? "d-none" : ""}
        >
          Print
        </Button>
      </div>

      {/* Modal for PDF Name and Print */}
      <Modal show={showPopup} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Set PDF Name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormControl
            type="text"
            value={values.pdf_name}
            name="pdf_name"
            placeholder="Enter pdf name"
            onChange={handleOnChange}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" size="lg" onClick={handleClose}>
            Close
          </Button>

          <Button
            disabled={Data.loading}
            variant="primary"
            size="lg"
            onClick={handleSave}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showShare} onHide={() => setShowShare(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Share</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup>
            <FormControl
              ref={linkInputRef}
              placeholder="Enter link to share"
              value={`${getRootDomain()}/capability_statement/share/${
                values.unique_link
              }`}
              // onChange={e => setLink(e.target.value)}
            />
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">Copy to Clipboard</Tooltip>
              }
            >
              <Button
                variant="outline-secondary"
                onClick={handleCopyToClipboard}
              >
                <FontAwesomeIcon icon={faCopy} />
              </Button>
            </OverlayTrigger>
          </InputGroup>
        </Modal.Body>
        <Modal.Footer className="justify-content-start">
          <Button
            className="text-start justify-content-start p-2 col-md-2 text-center"
            variant="primary"
            onClick={() => setShowShare(false)}
            size="lg"
          >
            <span>OK</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PDFVersion_A;
