import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { PROPOSAL_SET_VALUES } from "../../store/constants/proposalConstants";
import { iteratee } from "lodash";

const SimpleRichTextEditor = () => {
  const proposalValues = useSelector((state) => state.proposalValues);
  const { projectTypes } = useSelector((state) => state.projectTypeList);
  const [editorContent, setEditorContent] = useState();
  const [editorContentRough, setEditorContentRough] = useState();
  const [editorContentFinal, setEditorContentFinal] = useState();
  const [editorContentFluff, setEditorContentFluff] = useState();
  const [projectName, setProjectName] = useState();

  const dispatch = useDispatch();
  const singleProjectScopes = [
    "carpet cleaning",
    "change order",
    "final clean scope alone",
    "rough clean scope alone",
    "fluff clean scope alone",
    "residence home",
    "flooring service",
    "pressure wash",
    "scope broken out",
    "trailer",
  ];
  const handleEditorChange = (event, setEditorContent, against_check) => {
    setEditorContent(event.target.innerHTML);
    replacesChangedSections(event.target.innerHTML, against_check);
  };

  useEffect(() => {
    addTableBorders(proposalValues);
  }, [proposalValues.project_type]);

  useEffect(() => {
    addTableBorders(proposalValues);
  }, [proposalValues.user_template, proposalValues.phases, proposalValues?.template]);

  const replacesChangedSections = (modifiied, against_check) => {
    let select_project = projectTypes.find((projectType) => {
      return (
        projectType.name.toLowerCase() ==
        (proposalValues?.project_type?.toLowerCase() ||
          "any type general cleaning")
      );
    });
    const parser = new DOMParser();
    const doc = parser.parseFromString(
      proposalValues?.user_template ||
        select_project?.user_template ||
        select_project?.template,
      "text/html"
    );
    const changed = parser.parseFromString(modifiied, "text/html");
    if (
      typeof proposalValues?.project_type === "string" &&
      singleProjectScopes.indexOf(proposalValues?.project_type.toLowerCase()) !=
        -1
    ) {
      doc.body.innerHTML = changed.body.innerHTML;
    } else {
      doc.querySelectorAll("p").forEach((p) => {
        if (against_check.includes("rough") && p.textContent.toLowerCase().includes("rough")) {
          let iterate = true
          while(iterate) {
            if (p.nextElementSibling.textContent.toLowerCase().includes("final")){
              iterate = false
            } else {
              doc.body.removeChild(p.nextElementSibling)
            }
          }
          p.insertAdjacentHTML('afterend', changed.body.innerHTML);

        } else if (against_check.includes("final") && p.textContent.toLowerCase().includes("final")) {
          let iterate = true
          while(iterate) {
            if (p.nextElementSibling.textContent.toLowerCase().includes("fluff")){
              iterate = false
            } else {
              doc.body.removeChild(p.nextElementSibling)
            }
          }
          p.insertAdjacentHTML('afterend', changed.body.innerHTML);

        } else if (against_check.includes("fluff") && p.textContent.toLowerCase().includes("fluff")) {
          let iterate = true
          while(iterate) {
            if (p.nextElementSibling)
              doc.body.removeChild(p.nextElementSibling)
            else
              iterate = false
          }
          p.insertAdjacentHTML('afterend', changed.body.innerHTML);
        }
      });

    }
    dispatch({
      type: PROPOSAL_SET_VALUES,
      payload: { ["user_template"]: doc.body.innerHTML },
    });
  };

  const addTableBorders = (values) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(
      values.user_template || values.template,
      "text/html"
    );
    const tables = doc.querySelectorAll("table");
    tables.forEach((table) => {
      // Add borders to the table
      table.style.borderCollapse = "collapse";

      // Add borders to rows
      const rows = table.querySelectorAll("tr");
      rows.forEach((row) => {
        row.style.border = "1px solid black";
      });

      // Add borders to cells
      const cells = table.querySelectorAll("td, th");
      cells.forEach((cell) => {
        cell.style.border = "1px solid black";
        cell.style.padding = "8px"; // Optional: Add padding for better visual appearance
      });
    });

    if (
      typeof values?.project_type === "string" &&
      singleProjectScopes.indexOf(values?.project_type.toLowerCase()) == -1
    ) {
      if (!doc.querySelector("p")) return;

      doc.querySelectorAll('p').forEach((p) => {
        let html = '';
        if (p.textContent.toLowerCase().includes("rough")) {
          let currentElement = p;
          let iterate = true
          while(iterate) {
            if (currentElement.nextElementSibling == null || currentElement.nextElementSibling.textContent.toLowerCase().includes("final")){
              iterate = false
            } else if (currentElement.nextElementSibling != null) {
              html += currentElement.nextElementSibling.outerHTML
            }
            currentElement = currentElement.nextElementSibling
          }
          setEditorContentRough(html);
        } else if (p.textContent.toLowerCase().includes("final")) {
          let currentElement = p;
          let iterate = true
          while(iterate) {
            if (currentElement.nextElementSibling == null || currentElement.nextElementSibling.textContent.toLowerCase().includes("fluff")){
              iterate = false
            } else if(currentElement.nextElementSibling != null) {
              html += currentElement.nextElementSibling.outerHTML
            }
            currentElement = currentElement.nextElementSibling
          }
          setEditorContentFinal(html);
        } else if (p.textContent.toLowerCase().includes("fluff")) {
          let currentElement = p;
          let iterate = true
          while(iterate) {
            if (currentElement.nextElementSibling != null){
              html += currentElement.nextElementSibling.outerHTML
            } else {
              iterate = false
            }
            currentElement = currentElement.nextElementSibling
          }
          setEditorContentFluff(html);
        }
      });
    } else {
      setEditorContentRough("");
      setEditorContentFinal("");
      setEditorContentFluff("");
      if (values.phases !== "") {
        setEditorContent(doc.body.innerHTML);
      } else {
        setEditorContent(doc.querySelector("p").innerHTML);
      }
    }
  };

  return (
    <div className="editor-container">
      {typeof proposalValues?.project_type === "string" &&
      singleProjectScopes.indexOf(proposalValues?.project_type.toLowerCase()) !=
        -1 ? (
        <div
          style={{ textTransform: "initial", fontSize: "15px" }}
          className="editor"
          contentEditable={true}
          dangerouslySetInnerHTML={{ __html: editorContent }}
          onBlur={(e) => handleEditorChange(e, setEditorContent, "")}
          onPaste={(e) => e.preventDefault()}
        ></div>
      ) : (
        <h4>
          <b>
            <u>
              {proposalValues?.project_type?.toLowerCase() === "any type general cleaning"
                ? "Cleaning Scope:"
                : `Cleaning Scope - ${
                    projectTypes.find((projectType) =>
                      projectType.name.toLowerCase() == proposalValues?.project_type?.toLowerCase()
                    )?.name || ""
                  }`
              }
            </u>
          </b>
        </h4>
      )}
      {proposalValues.user_template !== "" &&
        proposalValues.phases.indexOf("rough") !== -1 &&
        editorContentRough != "" && (
          <>
            <h4>
              <b>Rough Clean</b>
            </h4>
            <div
              style={{ textTransform: "initial", fontSize: "15px" }}
              className="editor"
              contentEditable={true}
              dangerouslySetInnerHTML={{ __html: editorContentRough }}
              onBlur={(e) =>
                handleEditorChange(e, setEditorContentRough, "rough")
              }
              onPaste={(e) => e.preventDefault()}
            ></div>
          </>
        )}
      {proposalValues.user_template !== "" &&
        proposalValues.phases.indexOf("final") !== -1 &&
        editorContentFinal != "" && (
          <>
            <h4>
              <b>Final Clean</b>
            </h4>
            <div
              style={{ textTransform: "initial", fontSize: "15px" }}
              className="editor"
              contentEditable={true}
              dangerouslySetInnerHTML={{ __html: editorContentFinal }}
              onBlur={(e) =>
                handleEditorChange(e, setEditorContentFinal, "final")
              }
              onPaste={(e) => e.preventDefault()}
            ></div>
          </>
        )}
      {proposalValues.user_template !== "" &&
        proposalValues.phases.indexOf("fluff") !== -1 &&
        editorContentFluff != "" && (
          <>
            <h4>
              <b>Fluff Clean</b>
            </h4>
            <div
              style={{ textTransform: "initial", fontSize: "15px" }}
              className="editor"
              contentEditable={true}
              dangerouslySetInnerHTML={{ __html: editorContentFluff }}
              onBlur={(e) =>
                handleEditorChange(e, setEditorContentFluff, "fluff")
              }
              onPaste={(e) => e.preventDefault()}
            ></div>
          </>
        )}
    </div>
  );
};

export default SimpleRichTextEditor;
