import { Col, Form, InputGroup, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Message from "../Message";
import styled from "styled-components";
import Spinner from "../spinner/Spinner";
import ToolTip from "../tooltip/ToolTip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsRotate,
  faEdit,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import useIsMobile from "../../utils/helpers/use-is-mobile";

const StyledSpinner = styled(Spinner)`
  color: red;
  border: 1px red;
  .lds-dual-ring {
    text-align: center;
    color: red;
  }
`;
const CalculatorCostProfitResult = ({ bidAmount }) => {
  const values = useSelector((state) => state.costProfitValues);
  const [isEdit, setIsEdit] = useState(false);
  const isMobile = useIsMobile();
  const { loading: bidAmountLaborLoader, error: bidAmountLaborError } =
    useSelector((state) => state.bidAmountPricing);

  const {
    one_day_work,
    loading: stateLaborLoader,
    error: stateLaborError,
  } = useSelector((state) => state.stateLaborPricing);

  const [userOverrides, setUserOverrides] = useState({
    projectProfitAmount: null,
    suppliesCost: null,
    mobilizationCost: null,
    jobOverHeadAmount: null,
    workersCompensation: null,
  });

  // Add new state for additional inputs with initial values from local storage or defaults
  const [settings, setSettings] = useState({
    salesPercentage: 10,
    glExcessAutoPercentage: 2,
    officeAdminPercentage: 2,
    perLaborPrice: 0,
    mobilizationPercentage: 10,
    OfBidAmount: 5,
  });

  useEffect(() => {
    // Load settings from local storage when the component mounts
    const savedSettings =
      JSON.parse(localStorage.getItem("costProfitSettings")) || {};
    setSettings((prevSettings) => ({
      ...prevSettings,
      ...savedSettings,
    }));
  }, []);

  const handleSettingsChange = (e) => {
    const { name, value } = e.target;
    setSettings((prevSettings) => {
      const newSettings = {
        ...prevSettings,
        [name]: parseFloat(value) || 0,
      };
      // Save the updated settings to local storage
      localStorage.setItem("costProfitSettings", JSON.stringify(newSettings));
      return newSettings;
    });
  };

  const noOfDaysExpected = (bidAmount / one_day_work).toFixed(1);

  const accurateDaysOnSite = values?.accurate_days_on_site;

  const numberOfDaysToUse = values.use_accurate_days_on_site
    ? accurateDaysOnSite
    : noOfDaysExpected;

  const projectLaborCost =
    values?.laborers_on_site *
    values?.hours_crew_works_daily *
    numberOfDaysToUse *
    values?.hourly_labor_rate;

  const supplyCost = (settings.OfBidAmount / 100) * bidAmount;
  const noOfDaysExpectedCal = values.use_number_of_days
    ? Number(values.noOfDaysExpected)
    : Number(noOfDaysExpected);

  const noOfDaysExpectedCalToUse = values.use_accurate_days_on_site
    ? accurateDaysOnSite
    : noOfDaysExpectedCal;

  const mobilizationCost =
    settings.mobilizationPercentage *
    Number(values?.laborers_on_site) *
    noOfDaysExpectedCalToUse;

  // Computed values
  const jobOverHeadAmount =
    (settings.glExcessAutoPercentage / 100 +
      settings.salesPercentage / 100 +
      settings.officeAdminPercentage / 100) *
    bidAmount;

  let totalCost =
    parseFloat(projectLaborCost) +
    parseFloat(supplyCost) +
    parseFloat(mobilizationCost) +
    parseFloat(jobOverHeadAmount);

  let dollarAmount = 0;
  if (values?.i_outsource) {
    dollarAmount =
      values?.dollar_contract_amount > 0
        ? values?.dollar_contract_amount
        : (values?.dollar_contract_percent / 100) * bidAmount;
    totalCost = parseFloat(dollarAmount) + parseFloat(jobOverHeadAmount);
  }
  const projectProfitAmount = parseFloat(bidAmount) - parseFloat(totalCost);

  const profitMargin = (projectProfitAmount / bidAmount) * 100;

  function localStringToNumber(s) {
    return Number(String(s).replace(/[^0-9.,-]+/g, ""));
  }

  const convertBidAmountToCurrency = (value) => {
    var options = {
      maximumFractionDigits: 2,
      currency: "USD",
      currencyDisplay: "symbol",
    };
    return localStringToNumber(value).toLocaleString(undefined, options);
  };

  const handleInputChange = (e) => {
    let { name, value } = e.target;
    const updatedValues = {
      ...userOverrides,
      [name]: Number(value),
    };
  };

  const handleResetCalculations = () => {
    setSettings({
      salesPercentage: 10,
      glExcessAutoPercentage: 2,
      officeAdminPercentage: 2,
      perLaborPrice: 0,
      mobilizationPercentage: 10,
      OfBidAmount: 5,
    });
    localStorage.removeItem("costProfitSettings");
  };

  const handleEdit = () => {
    setIsEdit(true);
  };

  return (
    <>
      <Row className="d-flex justify-content-center">
        {bidAmountLaborLoader || stateLaborLoader ? (
          <div className="text-center">
            <StyledSpinner />
          </div>
        ) : stateLaborError || bidAmountLaborError ? (
          <Message variant="danger">
            {stateLaborError + "\n" + bidAmountLaborError}
          </Message>
        ) : (
          <Col lg={12} className="justify-content-center">
            <Row className="">
              <Col md={6} className="ms-0">
                <div className="mb-5">
                  <h3 className="font-weight-bolder">Project profit amount</h3>
                  <h3>
                    {" "}
                    $
                    {convertBidAmountToCurrency(
                      projectProfitAmount.toFixed(0).toLocaleString()
                    )}
                  </h3>
                  <p>
                    Calculated using Bid amount minus payroll, supplies,
                    mobilization, and overhead costs
                  </p>
                </div>
              </Col>
              <Col className="align-self-center col-md-6">
                <Row className="d-flex justify-content-center">
                  {/*<Col md={3} className="align-self-center pe-0">*/}
                  {/*  <h3>{Math.ceil(profitMargin)}</h3>*/}
                  {/*</Col>*/}
                  <Col md={6} className="ps-0 align-self-start ps-0">
                    <span className="fa-sm d-block">
                      <h3>
                        {Math.ceil(profitMargin)}{" "}
                        <span className="h6">Profit Margin %</span>
                      </h3>
                    </span>
                  </Col>
                </Row>
              </Col>
            </Row>

            {!values?.i_outsource ? (
              <>
                <Row className="justify-content-start mb-4">
                  <Col md={isEdit ? "5" : "12"}>
                    <h3 className="font-weight-bolder">
                      labor cost/payroll{" "}
                      <span className="">
                        <ToolTip information="Number of laborers x hours worked per day x hourly labor rate x number of days worked on the site." />
                      </span>
                    </h3>
                    <h3>
                      $
                      {convertBidAmountToCurrency(
                        projectLaborCost.toFixed(0).toLocaleString()
                      )}
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col md={isEdit ? "5" : "12"} className="text-center">
                    <div>
                      <h3 className="font-weight-bolder">
                        Supplies cost
                        <span className="">
                          <ToolTip information="Materials, tools, cleaning agents, and equipment used." />
                        </span>
                      </h3>
                      <h3>
                        $
                        {convertBidAmountToCurrency(
                          supplyCost.toFixed(0).toLocaleString()
                        )}
                      </h3>
                    </div>
                  </Col>
                  {isEdit && (
                    <Col md={6}>
                      <Row className="d-flex">
                        <Col md={isEdit ? 3 : 1} className="pe-0 ">
                          {isEdit && (
                            <InputGroup className="mb-3 ">
                              <Form.Control
                                name="OfBidAmount"
                                value={settings.OfBidAmount}
                                onChange={handleSettingsChange}
                                className="fa-sm"
                                aria-label="Amount (to the nearest dollar)"
                                type="number"
                                min="1"
                                step="1"
                              />
                            </InputGroup>
                          )}
                        </Col>
                        <Col
                          md={isEdit ? 6 : 5}
                          className="align-self-center ps-0"
                        >
                          <h6 className="fa-sm">% Of bid Amount</h6>
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col md={isEdit ? "5" : "12"}>
                    <div>
                      <h3 className="font-weight-bolder">
                        Mobilization cost
                        <span className="">
                          <ToolTip
                            information="Does not include the main work of a project, includes preparing
                       and transporting equipment, materials, and personnel to a project site,
                        also includes conducting pre-work site surveys.."
                          />
                        </span>
                      </h3>
                      <h3>
                        $
                        {convertBidAmountToCurrency(
                          mobilizationCost.toFixed(0).toLocaleString()
                        )}
                      </h3>
                    </div>
                  </Col>
                  {isEdit && (
                    <Col md={6}>
                      <Row className="d-flex">
                        <Col md={isEdit ? 3 : 1} className="pe-0 ">
                          {isEdit ? (
                            <InputGroup className="mb-3 ">
                              <Form.Control
                                name="mobilizationPercentage"
                                value={settings.mobilizationPercentage}
                                onChange={handleSettingsChange}
                                className="fa-sm"
                                aria-label="Amount (to the nearest dollar)"
                                type="number"
                                min="1"
                                step="1"
                              />
                            </InputGroup>
                          ) : (
                            <h4>{settings.mobilizationPercentage}</h4>
                          )}
                        </Col>
                        <Col
                          md={isEdit ? 4 : 4}
                          className="align-self-center ps-0"
                        >
                          <h6 className="fa-sm">$ per laborer</h6>
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Row>
              </>
            ) : (
              <Row className="justify-content-start mb-4">
                <Col md={isEdit ? "5" : "12"}>
                  <h3 className="font-weight-bolder">Subcontract cost</h3>
                  <h3>${dollarAmount.toFixed(2)}</h3>
                </Col>
              </Row>
            )}

            <Row className="">
              <Col md={isEdit ? "5" : "12"}>
                <div>
                  <h3 className="font-weight-bolder">
                    Overhead cost
                    <span className="">
                      <ToolTip
                        information="Sales expense, Insurances not workers comp, and office admin expenses.
                       Ongoing expenses that are not directly tied to producing a specific product
                       or service, but are necessary for the business to operate."
                      />
                    </span>
                  </h3>
                  <h3>
                    $
                    {convertBidAmountToCurrency(
                      jobOverHeadAmount.toFixed(0).toLocaleString()
                    )}
                  </h3>
                </div>
              </Col>
              {isEdit && (
                <Col md={2} className="align-self-center">
                  <h6 className="fa-sm mb-2">Sales</h6>
                  {isEdit ? (
                    <InputGroup className="mb-3 fa-sm">
                      <Form.Control
                        className="fa-sm"
                        aria-label="Amount (to the nearest dollar)"
                        name="salesPercentage"
                        value={settings.salesPercentage}
                        onChange={handleSettingsChange}
                        type="number"
                        min="0"
                        step="1"
                      />
                    </InputGroup>
                  ) : (
                    <h4>{settings.salesPercentage}</h4>
                  )}
                </Col>
              )}
              {isEdit && (
                <Col md={2} className="align-self-center">
                  <h6 className="fa-sm mb-2">GL/Excess/Auto</h6>
                  {isEdit ? (
                    <InputGroup className="mb-3 ">
                      <Form.Control
                        name="glExcessAutoPercentage"
                        value={settings.glExcessAutoPercentage}
                        onChange={handleSettingsChange}
                        className="fa-sm"
                        aria-label="Amount (to the nearest dollar)"
                        type="number"
                        min="0"
                        step="1"
                      />
                    </InputGroup>
                  ) : (
                    <h4>{settings.glExcessAutoPercentage}</h4>
                  )}
                </Col>
              )}
              {isEdit && (
                <Col md={3} className="align-self-center">
                  <h6 className="fa-sm mb-2">Office/Admin</h6>
                  {isEdit ? (
                    <InputGroup className="mb-3 ">
                      <Form.Control
                        name="officeAdminPercentage"
                        className="fa-sm"
                        aria-label="Amount (to the nearest dollar)"
                        onChange={handleSettingsChange}
                        type="number"
                        min="0"
                        step="1"
                        value={settings.officeAdminPercentage}
                      />
                    </InputGroup>
                  ) : (
                    <h4>{settings.officeAdminPercentage}</h4>
                  )}
                </Col>
              )}
            </Row>

            <Row>
              <Col md={isEdit ? "4" : "12"}>
                <hr className="hr border-top border-3 border-dark" />
                <div className="my-4">
                  <h3 className="font-weight-bolder">
                    Total Cost
                    <span className="">
                      <ToolTip information="The total project cost is the sum of labor expenses/payroll, supplies cost, mobilization cost, and overhead cost." />
                    </span>
                  </h3>
                  <h3>
                    $
                    {convertBidAmountToCurrency(
                      totalCost.toFixed(0).toLocaleString()
                    )}
                  </h3>
                </div>
              </Col>
            </Row>
          </Col>
        )}
        <Row className="justify-content-end">
          {!isEdit ? (
            <Col className="col-2" onClick={handleEdit}>
              {/* <FontAwesomeIcon
                icon={faEdit}
                // onClick={() => setIsEdit(true)}
                size="2xl"
                className="cursor-pointer"
              /> */}
              <span style={{ alignSelf: 'center', fontWeight: 'bold', fontSize: '16px' }}>Customize</span>

              {/*<Tooltip id="tooltip"></Tooltip>*/}
            </Col>
          ) : (
            <Col className="col-1" onClick={() => setIsEdit(false)}>
              <FontAwesomeIcon
                icon={faEye}
                className="cursor-pointer"
                size="2xl"
              />
            </Col>
          )}
          <Col className={isMobile?"col-2 ms-5":"col-1 ms-3"}>
            <FontAwesomeIcon
              className="cursor-pointer"
              size="2xl"
              icon={faArrowsRotate}
              onClick={handleResetCalculations}
            />{" "}
            Reset
          </Col>
        </Row>
      </Row>
    </>
  );
};

export default CalculatorCostProfitResult;
