import { isEmpty, toastSuccess } from "../../../utils/helpers/helper";

import {
  ADD_PAGE_FAVORITE,
  CREATE_PAGE_VIEW_FAILURE,
  CREATE_PAGE_VIEW_START,
  CREATE_PAGE_VIEW_SUCCESS,
  FETCH_PAGE_FAVORITE,
  FETCH_PAGE_FAVORITE_FAILURE,
  FETCH_PAGE_FAVORITE_START,
  FETCH_PAGE_FAVORITE_SUCCESS,
  FETCH_PAGE_FAVORITES,
  FETCH_PAGE_FAVORITES_FAILURE,
  FETCH_PAGE_FAVORITES_START,
  FETCH_PAGE_FAVORITES_SUCCESS,
  FETCH_PAGE_VIEW_FAILURE,
  FETCH_PAGE_VIEW_START,
  FETCH_PAGE_VIEW_SUCCESS,
  MANAGE_USER_BILLING_FAIL,
  MANAGE_USER_BILLING_REQUEST,
  MANAGE_USER_BILLING_SUCCESS,
  REMOVE_PAGE_FAVORITE,
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_SET_FREE_MODE_FAIL,
  USER_SET_FREE_MODE_REQUEST,
  USER_SET_FREE_MODE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
} from "../../constants/userConstants";
import {
  getUserAPI,
  manageUserBillingAPI,
  setFreeModeAPI,
} from "../../../utils/requests/users";
import axios from "axios";
import {
  DOMAIN_VERIFICATION_FAIL,
  DOMAIN_VERIFICATION_REQUEST,
  DOMAIN_VERIFICATION_SUCCESS,
  SITE_ROUTE_NOTES_SUCCESS,
  UPDATE_SITE_ROUTE_PROJECT_SUCCESS,
} from "../../actionTypes";
import {
  ONBOARDING_FAILURE,
  ONBOARDING_SUCCESS,
  SUBMIT_ONBOARDING_STEP,
} from "../../constants/onboardingConstants";

export const getUserDetail = (id) => {
  return async (dispatch) => {
    dispatch({
      type: USER_DETAILS_REQUEST,
    });
    return getUserAPI()
      .then(async (response) => {
        dispatch({
          type: USER_DETAILS_SUCCESS,
          payload: response,
        });
        dispatch({
          type: USER_SET_FREE_MODE_SUCCESS,
          payload: response.free_mode_action,
        });
        dispatch({
          type: UPDATE_SITE_ROUTE_PROJECT_SUCCESS,
          payload: response,
        })
        dispatch({
          type: SITE_ROUTE_NOTES_SUCCESS,
          notes: response.project_site_routes_notes,
        })
      })
      .catch((error) => {
        dispatch({
          type: USER_DETAILS_FAIL,
          payload: "Failed to fetch Company information",
        });
      });
  };
};

export const updateUserProfile = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_UPDATE_REQUEST,
    });

    let { access } = getState();
    if (isEmpty(access)) {
      access = JSON.parse(localStorage.getItem("token"));
    }

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${access}`,
      },
    };

    const { data } = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/api/v1/users/profile`,
      formData,
      config
    );

    dispatch({
      type: USER_UPDATE_SUCCESS,
      payload: data,
    });

    localStorage.setItem("user", JSON.stringify(data));
  } catch (error) {
    const message =
      error.response && error.response.data
        ? error.response.data
        : error.message;
    if (message === "Not authorized, token failed") {
      // dispatch(logout())
    }

    dispatch({
      type: USER_UPDATE_FAIL,
      payload: message,
    });
  }
};

export const setFreeModeAction = () => {
  return async (dispatch) => {
    dispatch({
      type: USER_SET_FREE_MODE_REQUEST,
    });
    return setFreeModeAPI()
      .then(async (response) => {
        dispatch({
          type: USER_SET_FREE_MODE_SUCCESS,
          payload: response.free_mode_count,
        });
      })
      .catch((error) => {
        dispatch({
          type: USER_SET_FREE_MODE_FAIL,
          payload: error,
        });
      });
  };
};

export const getManageUserBilling = (customer_id) => {
  return async (dispatch) => {
    dispatch({
      type: MANAGE_USER_BILLING_REQUEST,
    });
    return manageUserBillingAPI(customer_id)
      .then(async (response) => {
        dispatch({
          type: MANAGE_USER_BILLING_SUCCESS,
          payload: response,
        });

        document.location.href = response.session_url;
      })
      .catch((error) => {
        dispatch({
          type: MANAGE_USER_BILLING_FAIL,
          payload: error,
        });
      });
  };
};

export const sendDomainVerification = (email) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DOMAIN_VERIFICATION_REQUEST,
    });
    let { access } = getState();

    if (isEmpty(access)) {
      access = JSON.parse(localStorage.getItem("token"));
    }

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${access}`,
      },
    };
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/v1/emails/domain-verification`,
      { email },
      config
    );

    // const result = Object.values(
    //   data.reduce((acc, obj) => ({ ...acc, [obj.id]: obj }), {})
    // );
    toastSuccess("Email Successfully");
    dispatch({
      type: DOMAIN_VERIFICATION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    toastSuccess("There was a problem sending email");
    dispatch({
      type: DOMAIN_VERIFICATION_FAIL,
      payload: error,
    });
  }
};

export const submitOnboardingStep =
  (onBoardingData) => async (dispatch, getState) => {
    try {
      dispatch({ type: SUBMIT_ONBOARDING_STEP });
      let { access } = getState();

      if (isEmpty(access)) {
        access = JSON.parse(localStorage.getItem("token"));
      }

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access}`,
        },
      };
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/users/onboarding`,
        onBoardingData,
        config
      );

      toastSuccess("Thank you");
      dispatch({
        type: ONBOARDING_SUCCESS,
        payload: data,
      });
    } catch (error) {
      toastSuccess("There was a problem sending email");
      dispatch({
        type: ONBOARDING_FAILURE,
        payload: error,
      });
    }
  };

export const createPageView =
  (pageName, url, type) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CREATE_PAGE_VIEW_START,
      });
      let { access } = getState();

      if (isEmpty(access)) {
        access = JSON.parse(localStorage.getItem("token"));
      }

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access}`,
        },
      };
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/users/page-views`,
        { page_name: pageName, page_url: url, type },
        config
      );

      dispatch({
        type: CREATE_PAGE_VIEW_SUCCESS,
        payload: data,
      });
    } catch (error) {
      // toastSuccess("There was a problem creating view");
      dispatch({
        type: CREATE_PAGE_VIEW_FAILURE,
        payload: error,
      });
    }
  };

export const fetchPageView = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: FETCH_PAGE_VIEW_START,
    });
    let { access } = getState();

    if (isEmpty(access)) {
      access = JSON.parse(localStorage.getItem("token"));
    }

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${access}`,
      },
    };
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/users/page-views`,
      config
    );

    dispatch({
      type: FETCH_PAGE_VIEW_SUCCESS,
      payload: data,
    });
  } catch (error) {
    // toastSuccess("There was a problem creating view");
    dispatch({
      type: FETCH_PAGE_VIEW_FAILURE,
      payload: error,
    });
  }
};

// Async action creators
export const fetchPageFavorites = () => async (dispatch, getState) => {
  try {
    dispatch({ type: FETCH_PAGE_FAVORITES_START });
    let { access } = getState();
    if (isEmpty(access)) {
      access = JSON.parse(localStorage.getItem("token"));
    }

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${access}`,
      },
    };
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/users/page-favorites`,
      config
    );
    dispatch({ type: FETCH_PAGE_FAVORITES_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FETCH_PAGE_FAVORITES_FAILURE, payload: error });
    console.error("Error fetching favorites:", error);
  }
};

export const addPageFavorite = (pageData) => async (dispatch, getState) => {
  try {
    let { access } = getState();
    if (isEmpty(access)) {
      access = JSON.parse(localStorage.getItem("token"));
    }

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${access}`,
      },
    };
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/v1/users/page-favorites`,
      pageData,
      config
    );
    dispatch({ type: FETCH_PAGE_FAVORITE_SUCCESS, payload: data });
  } catch (error) {
    console.error("Error adding favorite:", error);
    // Handle error
  }
};

export const removePageFavorite = (pageData) => async (dispatch, getState) => {
  try {
    let { access } = getState();
    if (isEmpty(access)) {
      access = JSON.parse(localStorage.getItem("token"));
    }

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${access}`,
      },
      data: pageData,
    };
    const data = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/api/v1/users/page-favorites`,
      config
    ); // Update with the correct API endpoint
    dispatch({ type: FETCH_PAGE_FAVORITE_SUCCESS, payload: data });
  } catch (error) {
    console.error("Error removing favorite:", error);
    // Handle error
  }
};

export const fetchPageFavorite = (pageName) => async (dispatch, getState) => {
  try {
    dispatch({ type: FETCH_PAGE_FAVORITE_START });
    let { access } = getState();
    if (isEmpty(access)) {
      access = JSON.parse(localStorage.getItem("token"));
    }

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${access}`,
      },
    };
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/users/page-favorites?page_name=${pageName}`,
      config
    );
    dispatch({ type: FETCH_PAGE_FAVORITE_SUCCESS, payload: data });
  } catch (error) {
    console.error("Error fetching favorites:", error);
    dispatch({ type: FETCH_PAGE_FAVORITE_FAILURE, payload: error });
  }
};
