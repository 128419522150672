import React, { useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import {
  Accordion,
  Alert,
  Button,
  Col,
  Form,
  Row,
  ToggleButton,
} from "react-bootstrap";
import {
  BlueStyledAccordionHeader,
  DarkBlueStyledAccordionHeader,
  GreyStyledAccordionHeader,
  OrangeStyledButton,
  StyledToggleButton,
  StyledForm,
} from "./proposalForm.styled";
import { useDispatch, useSelector, Provider } from "react-redux";
import Select from "react-select";
import {
  getContactRoles,
  getProjectType,
  getProjectTypeDetail,
  getSelectedSections,
  saveProposal,
  updateProposal,
} from "../../store/actions/proposals/proposals.actions";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";

import StateDropdown from "../StateDropdown";
import {
  PROPOSAL_RESSET_SECTIONS,
  PROPOSAL_RESSET_VALUES,
  PROPOSAL_SET_SECTIONS,
  PROPOSAL_SET_VALUES,
} from "../../store/constants/proposalConstants";
import {
  formatPhoneNumber,
  isSubscriptionActive,
  toastError,
  userSubscribedPlans,
} from "../../utils/helpers/helper";
import { useParams, useSearchParams } from "react-router-dom";
import Spinner from "../spinner/Spinner";
import styled from "styled-components";
import { useNavigate } from "react-router";
import { TOTAL_FREE_MODE_COUNT } from "../../utils/constants/api";
import { getStateLaborPricingList } from "../../store/actions/mortgage_calculator/mortgage_calculator.actions";
import "./index.css";
import { createTemplate } from "../../utils/helpers/helper";
import { fetchSingleProject } from "../../store/actions/projects/projects.action";
import { savePaintProposal, updatePaintProposal } from "../../store/actions/paint_proposal/paint_proposal.action";

const StyledClearButton = styled(Button)`
  font-size: 12px;
  height: 25px;
  text-transform: lowercase;
  color: #fff;
  float: right;
  &:hover {
    color: #fff;
  }
  &:a {
    color: #fff;
  }
`;

const ProposalForm = ({ setModalShow, setPaymentModalShow }) => {
  const navigate = useNavigate();
  let params = useParams();
  let [searchParams] = useSearchParams();
  const bid_amount = searchParams.get("bidAmount");
  const project_type = searchParams.get("project_type");
  const project_name = searchParams.get("project_name");
  const project_state = searchParams.get("state");
  const project_phase = searchParams.get("phase");
  const project_slug = searchParams.get("url_slug");
  const davis = searchParams.get("davis");
  const union = searchParams.get("union");
  const { search } = window.location;
  const show_tab = new URLSearchParams(search).get("bidAmount");
  const { free_mode_count } = useSelector((state) => state.userFreeModeCount);
  const { projectTypeDetail } = useSelector((state) => state.projectTypeDetail);
  const { id } = params;
  const { user } = useSelector((state) => state.auth);
  const { user: profile } = useSelector((state) => state.userDetails);
  const { error: errorForm, loading: createLoader } = useSelector(
    (state) => state.proposalCreate
  );
  const { proposal, loading } = useSelector((state) => state.proposalDetail);
  const { proposalTemplates, loading: templatesLoading, error: templatesError } = useSelector((state) => state.proposalTemplates);
  const ganarpro_app = localStorage.getItem(`ganarpro_app${user?.id}`);
  const paint_app = ganarpro_app ? ganarpro_app == "paint" : !profile?.is_cleaning_company;
  const { loading: projectDetailsLoading, singleProject } = useSelector((state) => state.projects);
  const values = useSelector((state) => state.proposalValues);
  const sections = useSelector((state) => state.proposalSections);
  const { projectTypes, loading: projectsListLoading } = useSelector((state) => state.projectTypeList);
  const { roles, loading: rolesLoading } = useSelector((state) => state.contactRolesDetails);
  const { error: updateError } = useSelector((state) => state.projectUpdate);
  const [message] = useState("");
  const { stateLabors } = useSelector((state) => state.stateLaborPricingList);
  const [checked, setChecked] = useState(false);
  const [selectedProject, setSelectedProject] = useState({});
  const finalCleanProjects = [
    "carpet cleaning",
    "change order",
    "final clean scope alone",
    "flooring service",
    "pressure wash",
    "scope broken out",
    "trailer",
  ];
  const [phaseData, setPhaseData] = useState([
    { value: "rough", label: "rough" },
    { value: "final", label: "final" },
    { value: "fluff", label: "fluff" },
  ]);

  const dispatch = useDispatch();

  const handleSelect = (items) => {
    if (values.project_type) {
      const phases = items.map((item) => item.value).join("_");
      dispatch({ type: PROPOSAL_SET_VALUES, payload: { phases: phases } });
    } else {
      toastError("First Select Project Scope Type");
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    let newValues = values;

    let trueKeys = Object.keys(sections)
      .map((key) => {
        if (sections[key]) {
          return key;
        }
      })
      .filter((key) => key);

    if (user) {
      newValues = {
        ...values,
        ...profile,
        current_date: new Date().toUTCString().slice(0, 16),
      };
    }

    if(paint_app) {
      delete newValues['project_type']
      newValues = {
        ...newValues,
        sections: trueKeys.join(','),
      }

      dispatch(savePaintProposal(newValues));

    } else {
      let template =
        values?.user_template ||
        selectedProject?.user_template ||
        selectedProject?.template;
      // let doc = createTemplate(template, newValues);


      newValues = {
        ...newValues,
        project_type: selectedProject?.name,
        user_template: template,
        sections: trueKeys.join(","),
      };

      dispatch(saveProposal(newValues));
    }
  };

  const updateHandler = (e) => {
    e.preventDefault();
    let newValues = values;
    let trueKeys = Object.keys(sections)
      .map((key) => {
        if (sections[key]) {
          return key;
        }
      })
      .filter((key) => key);

    if (user) {
      newValues = {
        ...values,
        ...user,
        current_date: new Date().toUTCString().slice(0, 16),
      };
    }

    if (paint_app){
      delete newValues['project_type']
      newValues = {
        ...newValues,
        sections: trueKeys.join(","),
      };

      dispatch(updatePaintProposal(id, newValues));

    } else {
      newValues = {
        ...newValues,
        project_type: selectedProject?.name,
        sections: trueKeys.join(","),
      };

      dispatch(updateProposal(id, newValues));
    }
  };

  const handleSetModal = (e) => {
    e.preventDefault();
    localStorage.setItem("proposal", JSON.stringify(values));
    setModalShow(true);
  };

  const clearForm = (e) => {
    e.preventDefault();
    dispatch({ type: PROPOSAL_RESSET_VALUES });
    navigate("/my_proposal");
  };

  const handleSetPaymentModalShow = (e) => {
    e.preventDefault();
    localStorage.setItem("proposal", JSON.stringify(values));
    setPaymentModalShow(true);
  };
  // let isSubActive =
  //   profile?.free_template_count > 0 || profile?.subscription_id;
  const price_id = process.env.REACT_APP_PROPOSAL_APP;
  const price_id_painting = process.env.REACT_APP_GANARPRO_PAINTING;

  let handleButton = submitHandler;
  let buttonText = "Save";
  if (!user) {
    handleButton = handleSetModal;
  } else if (
    !isSubscriptionActive(user, paint_app? price_id_painting:price_id, user, free_mode_count) &&
    id
  ) {
    handleButton = handleSetPaymentModalShow;
    buttonText = "Update Proposal";
  } else if (!isSubscriptionActive(user, paint_app? price_id_painting:price_id, user, free_mode_count)) {
    handleButton = handleSetPaymentModalShow;
  } else if (user && proposal && id) {
    handleButton = updateHandler;
    buttonText = "Update Proposal";
  }

  const handleChange = (evt) => {
    const { name, value: newValue } = evt.target;

    dispatch({ type: PROPOSAL_SET_VALUES, payload: { [name]: newValue } });
  };

  const handleChangeSection = (evt) => {
    const { name } = evt.target;
    dispatch({
      type: PROPOSAL_SET_SECTIONS,
      payload: { [name]: evt.target.checked },
    });
  };

  const handleChangeProjectType = (e, typeId) => {
    if (!user) {
      setModalShow(true);
      return;
    }

    const { name, value } = e.target;
    let selectedProject = projectTypes.find(
      (projectType) => projectType.name.toLowerCase() === value.toLowerCase()
    );

    if (values?.project_details && selectedProject?.template == null) {
      selectedProject = values.project_details;
    }

    // Update proposal values based on selected project
    dispatch({
      type: PROPOSAL_SET_VALUES,
      payload: {
        project_type: selectedProject?.name?.toLowerCase(),
        user_template:
          selectedProject?.user_template || selectedProject?.template,
      },
    });

    // Update phases based on selected project type
    if (finalCleanProjects.indexOf(selectedProject.name.toLowerCase()) !== -1) {
      setPhaseData([{ value: "final", label: "final" }]);
      dispatch({ type: PROPOSAL_SET_VALUES, payload: { phases: "final" } });
    } else if (
      selectedProject.name.toLowerCase() === "fluff clean scope alone"
    ) {
      setPhaseData([{ value: "fluff", label: "fluff" }]);
      dispatch({ type: PROPOSAL_SET_VALUES, payload: { phases: "fluff" } });
    } else if (
      selectedProject.name.toLowerCase() === "rough clean scope alone"
    ) {
      setPhaseData([{ value: "rough", label: "rough" }]);
      dispatch({ type: PROPOSAL_SET_VALUES, payload: { phases: "rough" } });
    } else {
      setPhaseData([
        { value: "rough", label: "rough" },
        { value: "final", label: "final" },
        { value: "fluff", label: "fluff" },
      ]);
    }

    setSelectedProject(selectedProject);
  };

  const handleChangeProposalTemplate = (e) => {
    if (!user) {
      setModalShow(true);
      return;
    }

    const { name, value } = e.target;
    let selectedTemplate = proposalTemplates.find(
      (template) => template.name.toLowerCase() === value.toLowerCase()
    );
    dispatch({
      type: PROPOSAL_SET_VALUES,
      payload: {
        proposal_template: selectedTemplate?.name,
        user_scope: selectedTemplate?.user_scope || selectedTemplate?.scope,
        disclosure: selectedTemplate?.disclosure,
        scope_name: `Project Proposal for ${selectedTemplate.name.includes("Paint") ? "Paint" : "Drywall"}`
      },
    });

  };

  const handleResetScope = () => {
    let selectedTemplate = proposalTemplates.find(
      (template) => template.name.toLowerCase() === values?.proposal_template?.toLowerCase()
    );

    dispatch({
      type: PROPOSAL_SET_VALUES,
      payload: {
        user_scope: selectedTemplate?.scope,
      },
    });
  };

  const handleResetCleaning = () => {
    const select_project = projectTypes.find((projectType) => {
      return (
        projectType.name.toLowerCase() ===
        (values?.project_type?.toLowerCase() ?? "")
      );
    });
    dispatch({
      type: PROPOSAL_SET_VALUES,
      payload: {
        user_template: select_project.template,
      },
    });
  };

  const handleSelectProject = () => {
    let select_project = {};
    let value = values?.project_type || "Any Type General Cleaning";
    select_project = projectTypes.find((projectType) => {
      return projectType.name.toLowerCase() == value.toLowerCase();
    });

    if (values?.project_details && select_project?.template == null) {
      select_project = values.project_details;
    }

    dispatch({
      type: PROPOSAL_SET_VALUES,
      payload: {
        user_template:
          select_project?.user_template || select_project?.template,
        project_type: select_project?.name?.toLowerCase(),
      },
    });
    setSelectedProject(select_project);
  };

  const handleSinglePrice = (e) => {
    const { name, value } = e.target;
    dispatch({
      type: PROPOSAL_SET_SECTIONS,
      payload: {
        [name]: e.target.checked,
      },
    });
  };

  useEffect(() => {
    if (user) {
      dispatch(getSelectedSections(user?.id));
    } else {
      dispatch({ type: PROPOSAL_RESSET_SECTIONS });
    }
  }, []);

  useEffect(() => {
    if (projectTypes.length > 0) {
      handleSelectProject();
    }
  }, [projectTypes]);

  useEffect(() => {
    if (project_type) {
      let bidAmount = parseFloat(bid_amount).toFixed(2);
      let rough = 0;
      let final = 0;
      let fluff = 0;
      switch (project_phase) {
        case "rough":
          rough = bidAmount;
          break;
        case "final":
          final = bidAmount;
          break;
        case "fluff":
          fluff = bidAmount;
          break;
        case "rough_final":
        case "final_rough":
          rough = bidAmount * 0.4;
          final = 0.6 * bidAmount;
          break;
        case "rough_fluff":
        case "fluff_rough":
          rough = bidAmount * 0.6;
          fluff = bidAmount * 0.4;
          break;
        case "final_fluff":
        case "fluff_final":
          final = bidAmount * 0.65;
          fluff = 0.35 * bidAmount;
          break;
        case "rough_fluff_final":
        case "rough_final_fluff":
        case "final_fluff_rough":
        case "final_rough_fluff":
        case "fluff_final_rough":
        case "fluff_rough_final":
          rough = bidAmount * 0.3;
          final = bidAmount * 0.5;
          fluff = bidAmount * 0.2;
          break;
        default:
          rough = 0;
          final = 0;
          fluff = 0;
      }

      dispatch({
        type: PROPOSAL_SET_VALUES,
        payload: {
          project_type: project_type?.toLowerCase(),
          project_name,
          project_state,
          phases: project_phase,
          rough_clean_amount: parseInt(rough),
          final_clean_amount: parseInt(final),
          fluff_clean_amount: parseInt(fluff),
          bid_amount: bid_amount,
        },
      });

      dispatch(getProjectTypeDetail(project_type));
    } else if (values?.project_type) {
      handleSelectProject();
    }

    if (davis || union)
      dispatch({
        type: PROPOSAL_SET_SECTIONS,
        payload: {
          davis_bacon: davis == 'true',
          union_requirement: union == 'true'
        }
      })

    dispatch({
      type: PROPOSAL_SET_VALUES,
      payload: {
        project_name,
        project_state
      },
    });

    if (project_slug) {
      dispatch(fetchSingleProject(project_slug));
    }

    dispatch(getStateLaborPricingList());

    dispatch(getProjectType());
  }, [
    bid_amount,
    dispatch,
    id,
    project_name,
    project_state,
    project_type,
    project_phase,
  ]);

  useEffect(() => {
    if (project_type) {
      let selected = projectTypes.find((projectType) => {
        return projectType.name.toLowerCase() == project_type.toLowerCase();
      });
      const found = projectTypes.some((item) => item.name === selected?.name);

      if (!selected || !found) {
        selected = projectTypes.find((projectType) => {
          return projectType.name === "Any Type General Cleaning";
        });
      }

      setSelectedProject(selected);
      dispatch({
        type: PROPOSAL_SET_VALUES,
        payload: {
          project_type: selected?.name?.toLowerCase(),
          user_template: selected?.user_template || selected?.template,
        },
      });
    }
  }, [project_type, projectTypes]);

  useEffect(() => {
    if (singleProject) {
      let address =
        singleProject?.address !== null && singleProject?.address !== ""
          ? `${singleProject?.address}, `
          : "";
      address += `${singleProject?.city}, ${singleProject?.state}`;
      dispatch({
        type: PROPOSAL_SET_VALUES,
        payload: {
          project_address: address,
          // ["project_type"]: singleProject?.project_type?.toLowerCase(),
          customer_company_name: singleProject?.account_name,
          customer_address: singleProject?.account_billing_address,
          project_contact_1_name: singleProject?.primary_contact_name,
          project_contact_1_email: singleProject?.primary_contact_email,
          project_contact_1_phone: singleProject?.primary_contact_phone,
        },
      });

      if (singleProject.primary_contact_name) {
        dispatch({
          type: PROPOSAL_SET_SECTIONS,
          payload: {
            contact1: true
          }
        })
      }
    }

    if (singleProject) {
      dispatch(getContactRoles(singleProject?.id));
    }
  }, [singleProject]);

  useEffect(() => {
    if (roles) {
      for (let x = 0; x < roles.length; x++) {
        dispatch({
          type: PROPOSAL_SET_SECTIONS,
          payload: {
            [`contact${x + 1}`]: true,
          },
        });

        dispatch({
          type: PROPOSAL_SET_VALUES,
          payload: {
            [`project_contact_${x + 1}_name`]: roles[x]?.name,
            [`project_contact_${x + 1}_phone`]: roles[x]?.phone,
            [`project_contact_${x + 1}_email`]: roles[x]?.email,
          },
        });
      }
      let roles_length = roles.length || 0;
      for (let x = roles_length || 0; x < 5; x++) {
        dispatch({
          type: PROPOSAL_SET_SECTIONS,
          payload: {
            [`contact${x + 1}`]: false,
          },
        });
      }
    }
  }, [roles]);

  return (
    <StyledForm>
      {message && (
        <Alert variant="danger" className="text-capitalize">
          <Alert.Heading>Required Fields!</Alert.Heading>
          {message}
        </Alert>
      )}
      {updateError && (
        <Alert variant="danger">
          <Alert.Heading>Required Fields!</Alert.Heading>
          <ul>
            {Object.keys(updateError).map((error, index) => {
              return (
                <li key={index}>
                  {error} {updateError[error].toString()}
                </li>
              );
            })}
          </ul>
        </Alert>
      )}
      {errorForm && (
        <Alert variant="danger">
          <Alert.Heading>Required Fields!</Alert.Heading>
          <ul>
            {Object.keys(errorForm).map((error, index) => {
              return (
                <li key={index}>
                  {error} {errorForm[error].toString()}
                </li>
              );
            })}
          </ul>
        </Alert>
      )}
      {loading ||
      (paint_app && templatesLoading) ||
      projectDetailsLoading ||
      projectsListLoading ||
      rolesLoading ? (
        <div className="text-center">
          <Spinner />
        </div>
      ) : (
        <Accordion defaultActiveKey={["0", "1", "2", "3", "4"]} alwaysOpen>
          <Accordion.Item className="bg-white" eventKey="0">
            <GreyStyledAccordionHeader className="fs-1">
              Header Information
            </GreyStyledAccordionHeader>
            <Accordion.Body style={{ textTransform: "capitalize" }}>
              <br />
              <Row>
                <Form.Group as={Col}>
                  <StyledToggleButton
                    className={
                      sections?.scope_name
                        ? "toggle-checked-grey mb-2 w-100"
                        : "mb-2 w-100"
                    }
                    id="scope_name"
                    type="checkbox"
                    variant="outline-secondary"
                    name="scope_name"
                    checked={sections.scope_name}
                    onChange={handleChangeSection}
                  >
                    Scope Name
                  </StyledToggleButton>
                </Form.Group>
                <Form.Group as={Col}>
                  <StyledToggleButton
                    className={
                      sections.company_name
                        ? "toggle-checked-grey mb-2 w-100"
                        : "mb-2 w-100"
                    }
                    id="company_name"
                    type="checkbox"
                    variant="outline-secondary"
                    name="company_name"
                    checked={sections.company_name}
                    onChange={handleChangeSection}
                  >
                    Company Name
                  </StyledToggleButton>
                </Form.Group>
              </Row>
              <br />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="bg-white" eventKey="1">
            <GreyStyledAccordionHeader className="fs-1">
              Submitted by
            </GreyStyledAccordionHeader>
            <Accordion.Body style={{ textTransform: "capitalize" }}>
              <br />
              <Row>
                <Form.Group as={Col}>
                  <StyledToggleButton
                    className={
                      sections.contact_name
                        ? "toggle-checked-grey mb-2 w-100"
                        : "mb-2 w-100"
                    }
                    id="contact_name"
                    type="checkbox"
                    variant="outline-secondary"
                    name="contact_name"
                    checked={sections.contact_name}
                    onChange={handleChangeSection}
                  >
                    Contact Name
                  </StyledToggleButton>
                </Form.Group>
                <Form.Group as={Col}>
                  <StyledToggleButton
                    className={
                      sections.today_date
                        ? "toggle-checked-grey mb-2 w-100"
                        : "mb-2 w-100"
                    }
                    id="today_date"
                    type="checkbox"
                    variant="outline-secondary"
                    name="today_date"
                    checked={sections.today_date}
                    onChange={handleChangeSection}
                  >
                    Today Date
                  </StyledToggleButton>
                </Form.Group>
              </Row>
              <br />
              <Row>
                <Form.Group as={Col}>
                  <StyledToggleButton
                    className={
                      sections.contact_phone_email
                        ? "toggle-checked-grey mb-2 w-100"
                        : "mb-2 w-100"
                    }
                    id="contact_phone_email"
                    type="checkbox"
                    variant="outline-secondary"
                    name="contact_phone_email"
                    checked={sections.contact_phone_email}
                    onChange={handleChangeSection}
                  >
                    Contact Phone & Email
                  </StyledToggleButton>
                </Form.Group>
              </Row>
              <br />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="bg-white" eventKey="2">
            <BlueStyledAccordionHeader className="fs-1">
              Project Information
            </BlueStyledAccordionHeader>
            <Accordion.Body>
              <Row className="my-4">
                <Form.Group as={Col} className="d-flex align-items-end">
                  <StyledToggleButton
                    className={
                      sections.project_address
                        ? "toggle-checked-grey mb-2 w-100"
                        : "toggle-grey mb-2 w-100"
                    }
                    id="project_address"
                    type="checkbox"
                    variant="outline-secondary"
                    name="project_address"
                    checked={sections.project_address}
                    onChange={handleChangeSection}
                  >
                    Project Address
                  </StyledToggleButton>
                </Form.Group>
              </Row>
              {paint_app ? (
                <>
                  <Row className="my-3">
                    <Form.Group as={Col}>
                      <Row>
                        <Col lg={7} style={{ alignSelf: "center" }}>
                          <Form.Label className="fw-bolder">
                            Proposal Template{" "}
                            <span className="text-danger fw-bolder">*</span>
                          </Form.Label>
                        </Col>
                        <Col lg={5} className="text-end">
                          <button
                            type="button"
                            className="btn btn-outline-danger mb-1 w-100"
                            style={{ fontSize: "12px" }}
                            onClick={handleResetScope}
                          >
                            Reset Painting Scopes
                          </button>
                        </Col>
                      </Row>
                      <Form.Select
                        name="project_type"
                        onChange={handleChangeProposalTemplate}
                        value={values?.proposal_template?.toLowerCase()}
                        required
                      >
                        {proposalTemplates.map((item) => {
                          return (
                            <option key={item.id} value={item.name.toLowerCase()}>
                              {item.name}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                    <br />
                    <br />
                  </Row>
                </>
              ) : (
                <>
                  <Row className="my-3">
                  <Form.Group as={Col}>
                    <Row>
                      <Col lg={7} style={{ alignSelf: "center" }}>
                        <Form.Label className="fw-bolder">
                          Project Scope Type{" "}
                          <span className="text-danger fw-bolder">*</span>
                        </Form.Label>
                      </Col>
                      <Col lg={5} className="text-end">
                        <button
                          type="button"
                          className="btn btn-outline-danger mb-1 w-100"
                          style={{ fontSize: "12px" }}
                          onClick={handleResetCleaning}
                        >
                          Reset Cleaning Scopes
                        </button>
                      </Col>
                    </Row>
                    <Form.Select
                      name="project_type"
                      onChange={(e) => handleChangeProjectType(e, e.target.value)}
                      value={selectedProject?.name?.toLowerCase()}
                      required
                    >
                      {projectTypes.map((item) => {
                        return (
                          <option key={item.id} value={item.name.toLowerCase()}>
                            {item.name}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                  <br />
                  <br />
                  <br />
                  <Form.Group>
                    <Select
                      value={values?.phases
                        ?.split("_")
                        .filter((name) => name)
                        .map((name) => ({
                          value: name,
                          label: name,
                        }))}
                      isMulti
                      name="phase"
                      options={phaseData}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={handleSelect}
                    />
                  </Form.Group>
                </Row>
                <Form.Group as={Col} style={{ marginLeft: '8px', textTransform: 'math-auto' }}>
                  <Form.Check
                    type="checkbox"
                    name="single_bid_amount"
                    label="Do not break out phase pricing"

                    checked={sections?.single_bid_amount}
                    onClick={handleSinglePrice}
                  />
                </Form.Group>
                <br />
                <Row>
                  <Form.Group as={Row}>
                    <Form.Label className="fw-bolder">Bid amount</Form.Label>
                    <div className="col-6 h-75">
                      <StyledToggleButton
                        className={
                          sections.window_cleaning
                            ? "toggle-checked-grey mb-2 w-100"
                            : "toggle-grey mb-2 w-100"
                        }
                        id="window_cleaning"
                        type="checkbox"
                        variant="outline-secondary"
                        name="window_cleaning"
                        checked={sections.window_cleaning}
                        onChange={handleChangeSection}
                      >
                        Exterior Window Cleaning
                      </StyledToggleButton>
                    </div>
                    <div className="col-6 h-75">
                      <StyledToggleButton
                        className={
                          sections.pressure_washing
                            ? "toggle-checked-grey mb-2 w-100"
                            : "toggle-grey mb-2 w-100"
                        }
                        id="pressure_washing"
                        type="checkbox"
                        variant="outline-secondary"
                        name="pressure_washing"
                        checked={sections.pressure_washing}
                        onChange={handleChangeSection}
                      >
                        Pressure Washing
                      </StyledToggleButton>
                    </div>
                  </Form.Group>
                </Row>
              </>
              )}
              <Row className="mt-3">
                <Form.Group as={Row}>
                  <div className="col-6 h-100">
                    <StyledToggleButton
                      className={
                        sections.davis_bacon
                          ? "toggle-checked-grey mb-2 w-100"
                          : "toggle-grey mb-2 w-100"
                      }
                      id="davis_bacon"
                      type="checkbox"
                      variant="outline-secondary"
                      name="davis_bacon"
                      checked={sections.davis_bacon}
                      onChange={handleChangeSection}
                    >
                      Davis-Bacon Prevailing Wage
                    </StyledToggleButton>
                  </div>
                  <div className="col-6 h-100">
                    <StyledToggleButton
                      className={
                        sections.union_requirement
                          ? "toggle-checked-grey mb-2 w-100"
                          : "toggle-grey mb-2 w-100"
                      }
                      id="union_requirement"
                      type="checkbox"
                      variant="outline-secondary"
                      name="union_requirement"
                      checked={sections.union_requirement}
                      onChange={handleChangeSection}
                    >
                      Union Requirement
                    </StyledToggleButton>
                  </div>
                </Form.Group>
              </Row>
              <br />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="bg-white" eventKey="3">
            <DarkBlueStyledAccordionHeader>
              Customer Information
            </DarkBlueStyledAccordionHeader>
            <Accordion.Body>
              <Row className="my-4">
                <Form.Group as={Col}>
                  <StyledToggleButton
                    className={
                      sections.client_name
                        ? "toggle-checked-grey mb-2 w-100"
                        : "toggle-grey mb-2 w-100"
                    }
                    id="client_name"
                    type="checkbox"
                    variant="outline-secondary"
                    name="client_name"
                    checked={sections.client_name}
                    onChange={handleChangeSection}
                  >
                    Client Name
                  </StyledToggleButton>
                </Form.Group>
                <Form.Group as={Col}>
                  <StyledToggleButton
                    className={
                      sections.client_address
                        ? "toggle-checked-grey mb-2 w-100"
                        : "toggle-grey mb-2 w-100"
                    }
                    id="client_address"
                    type="checkbox"
                    variant="outline-secondary"
                    name="client_address"
                    checked={sections.client_address}
                    onChange={handleChangeSection}
                  >
                    Client Address
                  </StyledToggleButton>
                </Form.Group>
              </Row>

              <Row className="my-4">
                <Form.Group as={Col}>
                  <StyledToggleButton
                    className={
                      sections.contact1
                        ? "toggle-checked-grey mb-2 w-100"
                        : "toggle-grey mb-2 w-100"
                    }
                    id="contact1"
                    type="checkbox"
                    variant="outline-secondary"
                    name="contact1"
                    checked={sections.contact1}
                    onChange={handleChangeSection}
                  >
                    Contact 1
                  </StyledToggleButton>
                </Form.Group>
                <Form.Group as={Col}>
                  <StyledToggleButton
                    className={
                      sections.contact2
                        ? "toggle-checked-grey mb-2 w-100"
                        : "toggle-grey mb-2 w-100"
                    }
                    id="contact2"
                    type="checkbox"
                    variant="outline-secondary"
                    name="contact2"
                    checked={sections.contact2}
                    onChange={handleChangeSection}
                  >
                    Contact 2
                  </StyledToggleButton>
                </Form.Group>
              </Row>
              <Row className="my-4">
                <Form.Group as={Col}>
                  <StyledToggleButton
                    className={
                      sections.contact3
                        ? "toggle-checked-grey mb-2 w-100"
                        : "toggle-grey mb-2 w-100"
                    }
                    id="contact3"
                    type="checkbox"
                    variant="outline-secondary"
                    name="contact3"
                    checked={sections.contact3}
                    onChange={handleChangeSection}
                  >
                    Contact 3
                  </StyledToggleButton>
                </Form.Group>
                <Form.Group as={Col}>
                  <StyledToggleButton
                    className={
                      sections.contact4
                        ? "toggle-checked-grey mb-2 w-100"
                        : "toggle-grey mb-2 w-100"
                    }
                    id="contact4"
                    type="checkbox"
                    variant="outline-secondary"
                    name="contact4"
                    checked={sections.contact4}
                    onChange={handleChangeSection}
                  >
                    Contact 4
                  </StyledToggleButton>
                </Form.Group>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="bg-white" eventKey="4">
            <GreyStyledAccordionHeader>
              Signature Block
            </GreyStyledAccordionHeader>
            <Accordion.Body>
              <Row className="my-4">
                <Form.Group as={Col}>
                  <StyledToggleButton
                    className={
                      sections.sign
                        ? "toggle-checked-grey mb-2 w-100"
                        : "mb-2 w-100"
                    }
                    id="sign"
                    type="checkbox"
                    variant="outline-secondary"
                    name="sign"
                    checked={sections.sign}
                    onChange={handleChangeSection}
                  >
                    Sign
                  </StyledToggleButton>
                </Form.Group>
                <Form.Group as={Col}>
                  <StyledToggleButton
                    className={
                      sections.disclosure
                        ? "toggle-checked-grey mb-2 w-100"
                        : "mb-2 w-100"
                    }
                    id="disclosure"
                    type="checkbox"
                    variant="outline-secondary"
                    name="disclosure"
                    checked={sections.disclosure}
                    onChange={handleChangeSection}
                  >
                    Disclosure
                  </StyledToggleButton>
                </Form.Group>
              </Row>

              <Row className="my-4">
                <Form.Group as={Col}>
                  <StyledToggleButton
                    className={
                      sections.footer
                        ? "toggle-checked-grey mb-2 w-100"
                        : "mb-2 w-100"
                    }
                    id="footer"
                    type="checkbox"
                    variant="outline-secondary"
                    name="footer"
                    checked={sections.footer}
                    onChange={handleChangeSection}
                  >
                    Footer
                  </StyledToggleButton>
                </Form.Group>
                <Form.Group as={Col}>
                  <StyledToggleButton
                    className={
                      sections.logo
                        ? "toggle-checked-grey mb-2 w-100"
                        : "mb-2 w-100"
                    }
                    id="logo"
                    type="checkbox"
                    variant="outline-secondary"
                    name="logo"
                    checked={sections.logo}
                    onChange={handleChangeSection}
                  >
                    Logo
                  </StyledToggleButton>
                </Form.Group>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
          <Row>
            <Col className="text-center p-5">
              <OrangeStyledButton
                variant="warning"
                type="submit"
                onClick={handleButton}
                disabled={createLoader || loading}
              >
                <h4 className="p-2">
                  {" "}
                  {buttonText}{" "}
                  {(createLoader || loading) && (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  <span className="visually-hidden">Loading...</span>{" "}
                </h4>
              </OrangeStyledButton>
            </Col>
          </Row>
        </Accordion>
      )}
    </StyledForm>
  );
};
export default ProposalForm;
