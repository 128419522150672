export const PROPOSAL_CREATE_REQUEST = "PROPOSAL_CREATE_REQUEST";
export const PROPOSAL_CREATE_SUCCESS = "PROPOSAL_CREATE_SUCCESS";
export const PROPOSAL_CREATE_FAIL = "PROPOSAL_CREATE_FAIL";

export const USER_LIST_REQUEST = "USER_LIST_REQUEST";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAIL = "USER_LIST_FAIL";
export const USER_LIST_RESET = "USER_LIST_RESET";

export const USER_DELETE_REQUEST = "USER_DELETE_REQUEST";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_DELETE_FAIL = "USER_DELETE_FAIL";

export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAIL = "USER_UPDATE_FAIL";
export const USER_UPDATE_RESET = "USER_UPDATE_RESET";

export const USER_PROJECTS_SEQUENCE_REQUEST = "USER_PROJECTS_SEQUENCE_REQUEST";
export const USER_PROJECTS_SEQUENCE_SUCCESS = "USER_PROJECTS_SEQUENCE_SUCCESS";
export const USER_PROJECTS_SEQUENCE_FAIL = "USER_PROJECTS_SEQUENCE_FAIL";

export const PROPOSAL_SET_VALUES = "PROPOSAL_SET_VALUES";
export const PROPOSAL_RESSET_VALUES = "PROPOSAL_RESSET_VALUES";

export const PROPOSAL_SET_SECTIONS = "PROPOSAL_SET_SECTIONS";
export const PROPOSAL_RESSET_SECTIONS = "PROPOSAL_RESSET_SECTIONS";
export const PROPOSAL_SET_SELECTED_SECTIONS = "PROPOSAL_SET_SELECTED_SECTIONS";

export const PROPOSAL_LIST_REQUEST = "PROPOSAL_LIST_REQUEST";
export const PROPOSAL_LIST_SUCCESS = "PROPOSAL_LIST_SUCCESS";
export const PROPOSAL_LIST_FAIL = "PROPOSAL_LIST_FAIL";

export const PROPOSAL_DETAIL_REQUEST = "PROPOSAL_DETAIL_REQUEST";
export const PROPOSAL_DETAIL_SUCCESS = "PROPOSAL_DETAIL_SUCCESS";
export const PROPOSAL_DETAIL_FAIL = "PROPOSAL_DETAIL_FAIL";

export const PROPOSAL_UPDATE_REQUEST = "PROPOSAL_UPDATE_REQUEST";
export const PROPOSAL_UPDATE_SUCCESS = "PROPOSAL_UPDATE_SUCCESS";
export const PROPOSAL_UPDATE_FAIL = "PROPOSAL_UPDATE_FAIL";

export const PROPOSAL_DOWNLOAD_REQUEST = "PROPOSAL_DOWNLOAD_REQUEST";
export const PROPOSAL_DOWNLOAD_SUCCESS = "PROPOSAL_DOWNLOAD_SUCCESS";
export const PROPOSAL_DOWNLOAD_FAIL = "PROPOSAL_DOWNLOAD_FAIL";

export const PROPOSAL_DELETE_REQUEST = "PROPOSAL_DELETE_REQUEST";
export const PROPOSAL_DELETE_SUCCESS = "PROPOSAL_DELETE_SUCCESS";
export const PROPOSAL_DELETE_FAIL = "PROPOSAL_DELETE_FAIL";

export const SUBSCRIPTION_CREATE_REQUEST = "SUBSCRIPTION_CREATE_REQUEST";
export const SUBSCRIPTION_CREATE_SUCCESS = "SUBSCRIPTION_CREATE_SUCCESS";
export const SUBSCRIPTION_CREATE_FAIL = "SUBSCRIPTION_CREATE_FAIL";

export const PROJECT_TYPE_LIST_REQUEST = "PROJECT_TYPE_LIST_REQUEST";
export const PROJECT_TYPE_LIST_SUCCESS = "PROJECT_TYPE_LIST_SUCCESS";
export const PROJECT_TYPE_LIST_FAIL = "PROJECT_TYPE_LIST_FAIL";

export const PROPOSAL_TEMPLATE_LIST_REQUEST = "PROPOSAL_TEMPLATE_LIST_REQUEST";
export const PROPOSAL_TEMPLATE_LIST_SUCCESS = "PROPOSAL_TEMPLATE_LIST_SUCCESS";
export const PROPOSAL_TEMPLATE_LIST_FAIL = "PROPOSAL_TEMPLATE_LIST_FAIL";

export const PROJECT_TYPE_DETAIL_REQUEST = "PROJECT_TYPE_DETAIL_REQUEST";
export const PROJECT_TYPE_DETAIL_SUCCESS = "PROJECT_TYPE_DETAIL_SUCCESS";
export const PROJECT_TYPE_DETAIL_FAIL = "PROJECT_TYPE_DETAIL_FAIL";

export const CONTACT_DETAIL_REQUEST = "CONTACT_DETAIL_REQUEST";
export const CONTACT_DETAIL_SUCCESS = "CONTACT_DETAIL_SUCCESS";
export const CONTACT_DETAIL_FAIL = "CONTACT_DETAIL_FAIL";

export const UPLOAD_PROPOSAL_DOCUMENT_REQUEST =
  "UPLOAD_PROPOSAL_DOCUMENT_REQUEST";
export const UPLOAD_PROPOSAL_DOCUMENT_SUCCESS =
  "UPLOAD_PROPOSAL_DOCUMENT_SUCCESS";
export const UPLOAD_PROPOSAL_DOCUMENT_FAILURE =
  "UPLOAD_PROPOSAL_DOCUMENT_FAILURE";

export const PROPOSAL_DOCUMENT_REQUEST = "PROPOSAL_DOCUMENT_REQUEST";
export const PROPOSAL_DOCUMENT_SUCCESS = "PROPOSAL_DOCUMENT_SUCCESS";
export const PROPOSAL_DOCUMENT_FAIL = "PROPOSAL_DOCUMENT_FAIL";

export const PROPOSAL_DOCUMENT_DELETE_REQUEST =
  "PROPOSAL_DOCUMENT_DELETE_REQUEST";
export const PROPOSAL_DOCUMENT_DELETE_SUCCESS =
  "PROPOSAL_DOCUMENT_DELETE_SUCCESS";
export const PROPOSAL_DOCUMENT_DELETE_FAIL = "PROPOSAL_DOCUMENT_DELETE_FAIL";
