import Modal from "react-bootstrap/Modal";
import LoginForm from "../forms/LoginForm";
import styled from "styled-components";
import SubscriptionModal from "../subscriptionModal";
import { useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Col } from "react-bootstrap";
import OneTimePricingCard from "../oneTimePricingCard";

const StyledModal = styled(Modal)`
  .modal-content {
    border-radius: 10px;
  }
`;
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
const product = {
  title: "Ganarpro Site Route Router",
  features: "Lifetime access of the site route router",
  price: "25.00",
  purchased: true,
  key: "site_router",
  price_id: process.env.REACT_APP_SITE_ROUTE,
};

const PricingModal = (props) => {
  const { user: profile } = useSelector((state) => state.userDetails);

  return (
    <StyledModal
      {...props}
      backdrop="static"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton={props.closeButton}>
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Elements key={"1"} stripe={stripePromise}>
          <Col md={12} xs={12} className="mb-5 mb-lg-0 col-md-12 col-lg-12">
            <OneTimePricingCard
              product={product}
              profile={profile}
            />
          </Col>
        </Elements>
      </Modal.Body>
    </StyledModal>
  );
};
PricingModal.defaultProps = {
  closeButton: true,
};
export default PricingModal;
