import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Form,
  Button,
  Row,
  Col,
  Container,
  Tabs,
  Tab,
  Card,
  Alert,
} from "react-bootstrap";
import {
  deleteProposalDocuments,
  fetchProposalDocuments,
  uploadProposalDocuments,
} from "../../store/actions/proposals/proposals.actions";
import CustomFileInput from "./components/CustomFileInput";
import { extractFileName } from "../../utils/helpers/helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { BsDownload } from "react-icons/bs";

const MAX_FILE_SIZE = 5242880;
const ALLOWED_FILE_TYPES = [
  "application/pdf",
  "image/png",
  "image/jpeg",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

const Planify = () => {
  const [searchParams] = useSearchParams();
  const defaultTabKey = searchParams.get("tabKey") || "home";
  const [hidden, setHidden] = useState(true);

  const dispatch = useDispatch();
  const [selectedFiles, setSelectedFiles] = useState({
    w9: null,
    coi: null,
    capability_statement: null,
    locations: [],
  });
  const [error, setError] = useState("");
  const [fileInputValues, setFileInputValues] = useState({
    w9: "",
    coi: "",
    capability_statement: "",
  });
  const proposalDocuments = useSelector(
    (state) => state.proposalDocumentDetail
  );
  const proposalDocumentUpload = useSelector(
    (state) => state.proposalDocumentUpload
  );
  const { loading, error: errorLoader, fileDetail } = proposalDocuments;

  useEffect(() => {
    if (proposalDocumentUpload.uploadSuccess) {
      dispatch(fetchProposalDocuments());
      dispatch({ type: "UPLOAD_RESET" }); // Reset upload success status
    }
  }, [dispatch, proposalDocumentUpload.uploadSuccess]);

  useEffect(() => {
    if (proposalDocuments.fileDetail) {
      setSelectedFiles({
        w9: proposalDocuments.fileDetail.w9 || "",
        coi: proposalDocuments.fileDetail.coi || "",
        capability_statement:
          proposalDocuments.fileDetail.capability_statement || "",
      });
      setFileInputValues({
        w9: extractFileName(proposalDocuments.fileDetail.w9) || "",
        coi: extractFileName(proposalDocuments.fileDetail.coi) || "",
        capability_statement:
          extractFileName(proposalDocuments.fileDetail.capability_statement) ||
          "",
      });
      setHidden(fileDetail?.hidden);
    }
  }, [proposalDocuments.fileDetail]);

  // Fetch proposal documents on component mount
  useEffect(() => {
    dispatch(fetchProposalDocuments());
  }, [dispatch]);

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];

    setFileInputValues({ ...fileInputValues, [name]: file.name });

    if (file) {
      if (!ALLOWED_FILE_TYPES.includes(file.type)) {
        setError(
          `The file type for ${file.name} is not allowed. Only PDF, DOC, PNG, and JPG files are accepted.`
        );
        setSelectedFiles({ ...selectedFiles, [name]: null }); // Reset the file input
      } else if (file.size > MAX_FILE_SIZE) {
        setError(`The file ${file.name} is too large. Maximum size is 5MB.`);
        setSelectedFiles({ ...selectedFiles, [name]: null }); // Reset the file input
      } else {
        setError("");
        setSelectedFiles({ ...selectedFiles, [name]: file });
      }
    }
  };

  const handleUpload = (e) => {
    e.preventDefault();
    if (
      !selectedFiles.coi &&
      !selectedFiles.w9 &&
      !selectedFiles.capabilityStatement
    ) {
      alert("No files or location selected ");
      return;
    }

    if (error) {
      alert(error);
      return;
    }

    const formData = new FormData();
    Object.entries(selectedFiles).forEach(([key, value]) => {
      if (value) formData.append(key, value);
    });
    dispatch(uploadProposalDocuments(selectedFiles));
  };

  const hideUploadOptions = () => {
    setHidden(!hidden);
  };

  const handleDeleteFile = (fileType) => {
    if (window.confirm("Are you sure you want to delete this file?")) {
      dispatch(deleteProposalDocuments({ fileType }));
      setFileInputValues((prev) => ({ ...prev, [fileType]: "" }));
    }
    setTimeout(function () {
      window.location.href = "/proposal";
    }, 1000);
  };

  const handleViewFile = (e, fileUrl) => {
    e.preventDefault();
    if (fileUrl) {
      const secureUrl = fileUrl.replace("http://", "https://");
      const link = document.createElement("a");
      link.href = secureUrl;
      link.target = "_blank";
      link.download = extractFileName(fileUrl);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <Container>
      {error && <Alert variant="danger">{error}</Alert>}
      <Row className="justify-content-center mt-5">
        <Tabs defaultActiveKey={defaultTabKey} id="justify-tab-example" className="mb-3">
          <Tab eventKey="home" title="Getting Started">
            <Row>
              <h1 className="fw-bolder mt-5 display-3">
                Streamline your proposals: Generate or Download Templates
              </h1>
              <Col md={8} className="justify-content-center mx-auto my-5 mt-3">
                <p className="my-5 h3 lh-base">
                  Ganarpro Proposal provides a document generator and access to
                  construction cleaning scope of work templates. It streamlines
                  the process by delivering estimator and project manager
                  approved commercial construction scope of work requirements.
                </p>
              </Col>
            </Row>
            <Row className="justify-content-evenly mt-5 ">
              <Col md={4} className="gy-3 my-sm-5">
                <Link
                  to="/my_proposal"
                  className="text-decoration-none text-dark"
                >
                  <Card className="h-100 p-0" border="light">
                    <Card.Body className="d-flex flex-column">
                      <Card.Title className="fw-bolder mb-5 ">
                        {" "}
                        <h2 className="text-start fw-bolder">
                          Document generator
                        </h2>
                      </Card.Title>
                      <Card.Text className="h3 text-start mb-5 lh-base">
                        Experience the efficiency of our automated merging
                        feature as you create compelling proposals with ease
                      </Card.Text>
                      <Button
                        variant="primary"
                        className="text-center col-md-7 mt-5 py-md-2"
                      >
                        <span className="h3 ">CREATE proposal</span>
                      </Button>
                    </Card.Body>
                  </Card>
                </Link>
              </Col>
              <Col md={4} className="gy-3 my-sm-5">
                <Link
                  to="/proposal_templates"
                  className="text-decoration-none text-dark"
                >
                  <Card className="h-100 p-0" border="light">
                    <Card.Body className="d-flex flex-column">
                      <Card.Title className="fw-bolder mb-5 ">
                        {" "}
                        <h2 className="text-start fw-bolder">Templates</h2>
                      </Card.Title>
                      <Card.Text className=" text-start mb-5 h3 lh-base">
                        Empower your brand and streamline your document creation
                        process with our set on. downloadable construction
                        cleaning proposal templates that will serve you
                        indefinitely.
                      </Card.Text>
                      <Button
                        variant="primary"
                        className="text-center col-md-7 mt-5 py-md-2 px-md-3"
                      >
                        <span className="h3 ">VIEW templates</span>
                      </Button>
                    </Card.Body>
                  </Card>
                </Link>
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="profile" title="Settings">
            <Container className="mt-5">
              <Row className="mb-5">
                <Col md={3}>
                  <h4>Update Your Proposal Header Information</h4>
                </Col>
                <Col md={2}>
                  <Link to="/profile" className="text-decoration-none">
                    <h4>My Profile</h4>
                  </Link>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={2} className="align-self-center">
                  <Form.Group controlId="formFile1" className="mb-3">
                    <Form.Check
                      type="checkbox"
                      id="default-checkbox"
                      label="Add supporting docs to Proposal Download"
                      onChange={hideUploadOptions}
                      checked={!hidden}
                    />
                  </Form.Group>
                </Col>
                {!hidden && (
                  <>
                    <Col md={3} className="">
                      <Row className="">
                        <>
                          {fileDetail?.w9 && (
                            <>
                              <Col md={1} className="align-self-center mt-3">
                                <Button
                                  variant="primary"
                                  onClick={(e) =>
                                    handleViewFile(e, selectedFiles?.w9)
                                  }
                                >
                                  <BsDownload size={18}></BsDownload>
                                </Button>
                              </Col>
                              <Col
                                md={1}
                                className="align-self-center mt-3 ms-3"
                              >
                                <Button
                                  variant="primary"
                                  onClick={(e) => handleDeleteFile("w9")}
                                >
                                  <FontAwesomeIcon icon={faTrashAlt} />
                                </Button>
                              </Col>
                            </>
                          )}
                          <Col md={8}>
                            <CustomFileInput
                              label="W9"
                              name="w9"
                              fileInputValue={fileInputValues.w9}
                              onFileChange={handleFileChange}
                            />
                          </Col>
                        </>
                      </Row>
                    </Col>
                    <Col md={3} className="">
                      <Row className="">
                        <>
                          {fileDetail?.coi && (
                            <>
                              <Col md={1} className="align-self-center mt-3">
                                <Button
                                  variant="primary"
                                  onClick={(e) =>
                                    handleViewFile(e, selectedFiles?.coi)
                                  }
                                >
                                  <BsDownload size={18}></BsDownload>
                                </Button>
                              </Col>
                              <Col
                                md={1}
                                className="align-self-center mt-3 ms-3"
                              >
                                <Button
                                  variant="primary"
                                  onClick={(e) => handleDeleteFile("coi")}
                                >
                                  <FontAwesomeIcon icon={faTrashAlt} />
                                </Button>
                              </Col>
                            </>
                          )}
                          <Col md={8}>
                            <CustomFileInput
                              label="COI"
                              name="coi"
                              fileInputValue={fileInputValues.coi}
                              onFileChange={handleFileChange}
                            />
                          </Col>
                        </>
                      </Row>
                    </Col>

                    <Col md={3} className="">
                      <Row className="">
                        <>
                          {fileDetail?.capability_statement && (
                            <>
                              <Col md={1} className="align-self-center mt-3">
                                <Button
                                  variant="primary"
                                  onClick={(e) =>
                                    handleViewFile(
                                      e,
                                      selectedFiles?.capability_statement
                                    )
                                  }
                                >
                                  <BsDownload size={18}></BsDownload>
                                </Button>
                              </Col>
                              <Col
                                md={1}
                                className="align-self-center mt-3 ms-3"
                              >
                                <Button
                                  variant="primary"
                                  onClick={(e) =>
                                    handleDeleteFile("capability_statement")
                                  }
                                >
                                  <FontAwesomeIcon icon={faTrashAlt} />
                                </Button>
                              </Col>
                            </>
                          )}
                          <Col md={8}>
                            <CustomFileInput
                              label="Capability Statement"
                              name="capability_statement"
                              fileInputValue={
                                fileInputValues.capability_statement
                              }
                              onFileChange={handleFileChange}
                            />
                          </Col>
                        </>
                      </Row>
                    </Col>

                    <Col className="align-self-center col-md-1">
                      <Button variant="primary" onClick={handleUpload}>
                        Upload
                      </Button>
                    </Col>
                  </>
                )}
              </Row>
            </Container>
          </Tab>
        </Tabs>
      </Row>
    </Container>
  );
};

export default Planify;
