import React, { useState } from "react";
import "../PricingCard/PricingCard.css";
import LoginModal from "../LoginModal";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import "../StripePaymentCard/StripePaymentCard.css";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Button, Col, Row } from "react-bootstrap";
import { createSubscriptionAPI } from "../../utils/requests/proposals";
import { toastError, toastSuccess } from "../../utils/helpers/helper";
import Card from "react-bootstrap/Card";
import { getUserDetail } from "../../store/actions/users/users.actions";
import { UPDATE_PRICING_MODAL } from "../../store/actionTypes";

const useOptions = () => {
  return {
    style: {
      base: {
        iconColor: "#c4f0ff",
        color: "#000",
        fontWeight: "500",
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": {
          color: "#fce883",
        },
        "::placeholder": {
          color: "#87BBFD",
        },
      },
      invalid: {
        iconColor: "#eb1c26",
        color: "#eb1c26",
      },
    },
  };
};

const OneTimePricingCard = ({ num, product, profile }) => {
  const [loginModalShow, setLoginModalShow] = useState(false);
  const dispatch = useDispatch();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const options = useOptions();
  const { user } = useSelector((state) => state.auth);

  const handleSubmit = async (event) => {
    setError(null);
    event.preventDefault();
    if (!user) {
      setLoginModalShow(true);
      return;
    }

    if (!stripe || !elements) {
      return;
    }

    if (error) {
      elements.getElement("card").focus();
      return;
    }

    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });
    if (payload.error) {
      setError(payload.error);
    } else {
      setLoading(true);
      let data = {
        one_time_payment: true,
        payment_method: payload.paymentMethod.id,
        price_id: product.price_id,
        amount: "$25.00",
        description: "Lifetime access of the site route router",
        ...user,
      };

      createSubscriptionAPI(data)
        .then(async (response) => {
          toastSuccess("Payment is Successful");
          dispatch({
            type: UPDATE_PRICING_MODAL,
            pricing_modal: false,
          })
          dispatch(getUserDetail())
        })
        .catch((error) => {
          setLoading(false);
          toastError(`Payment Failed`);
        });
      setLoading(true);
    }
  };

  return (
    <>
      <Row className="text-end d-flex justify-content-end fw-bolder" style={{ fontSize: '15px' }}>
        Payment
      </Row>
      <Card className="text-start p-2 h-100">
        <Card.Body>
          <Row className="fw-">
            <Card.Img
              variant="top"
              src={product.icon}
              className="col-md-2"
              style={{ width: "100px" }}
            />
            <Card.Title className="col-md-12 align-self-center fw-bolder">
              {product.title}
            </Card.Title>
          </Row>
          <Card.Text className="fw-semibold my-3">{product.features}</Card.Text>
          <Card.Text className="my-4 mb-0">Starting</Card.Text>
          <Card.Text>
            <span className="h4 fw-bolder">${product.price}</span> - One-time payment not included in any other subscription
          </Card.Text>
          <Row className="mt-auto h-25 text-center ">
            <form onSubmit={handleSubmit}>
                <label>Card details</label>
                {error && <Alert variant="danger">{error.message}</Alert>}
                <CardElement
                options={options}
                onChange={(e) => {
                    setError(e.error);
                    // setCardComplete(e.complete);
                }}
                />
                <Button
                type="submit"
                variant="primary"
                className="p-3 text-center justify-content-center"
                disabled={!stripe || loading}
                >
                {loading ? "processing..." : "Pay"}
                </Button>
            </form>
          </Row>
        </Card.Body>
      </Card>
      <LoginModal
        show={loginModalShow}
        onHide={() => setLoginModalShow(false)}
      />
    </>
  );
};

export default OneTimePricingCard;
